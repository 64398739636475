<template>
    <v-container fill-height fluid>
        <v-card class="fill-height text-center" width="100%">
            <v-card-title class="text-left">
                имя туловища
            </v-card-title>
            <v-card-subtitle class="text-left">
                тут типа тоже какой-то текст, нафига надо все это заполнять
            </v-card-subtitle>
            <v-card-text>
                <v-form class="jet-form" ref="form">
                    <v-container fluid>
                        <v-layout row wrap>
                            <v-flex offset-xs1 xs6>
                                <jet-input ref="tenantID" label="Организация" type="id" v-model="tenantID"
                                    :disabled="false"
                                    :required="true"
                                    uri="sin2:/v:173ddb34-52dd-4425-9f5a-2f44e4e1a00f/?hierarchy=false" 
                                    :max-length="128"/>
                                <jet-input ref="postName" label="Должность" type="string" v-model="postName"
                                    :required="true" 
                                    :disabled="false"/>
                                <jet-input ref="others" placeholder="Что будешь в системе делать" type="string" v-model="others"
                                    :rows="10"
                                    :required="true" 
                                    :disabled="false"/>                                
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn outlined tile small color="primary" @click="save">
                    Принять
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import JetInput from '@/components/editors/JetInput';

export default {
    name: 'Verification',
    components: {
        JetInput
    },
    provide() {
        const self = this;
        return {
            owner: {
                reg(comp){},
                unreg(comp){},
                val(name, val){
                        self.values[name] = (!!val || (val==0)) ? val : null; //TODO
                }
            }
        };
    },
    data() {
        return {
            tenantID: null,
            postName: null,
            others  : null
        }
    },
    mounted() {
        console.log(this.$router);
        const esia = this.$store.getters['profile/checkEsia'];
        console.log(esia);
    },
    methods: {
        save() {
            if ( !this.$refs.form.validate() )
                return;
            console.log('save');
        }
    },
    computed: {
        isAuth() {
            return this.$store.getters['profile/isAuthenticated'];
        }
    },
    watch: {
        isAuth(val) {
            if (val) {
                  this.$router.replace('/');
            }
        }
    }
}
</script>